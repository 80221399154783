import { Container, Row } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import { useDispatch, useSelector } from 'react-redux';
import { selectLogin, setShowLogin, setShowSignUp, setMailForgotSent, setPromptFirstLogin, setShowProfile } from '../../../slice/ModalVisibilitySlice.js';
import { setPasswordInit, selectPassword } from '../../../slice/passwordSlice';
import { useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { selectUserData, setUserData } from '../../../slice/userDataSlice';
import { selectBearer, setBearer, setRefreshToken } from '../../../slice/bearerSlice';
import { setErrModalVal, selectErrModalVal, setErrModalValInit } from '../../../slice/errModalValidationSlice';
import Password from './Password';
import { Url } from '../../../constants/global.js';
import { selectTheme, setLanguage } from '../../../slice/SettingSlice.js';
import { isMobile } from 'react-device-detect';
import AccountIcon from '../../../Images/AccountIcon.js';
import { setInputMessage } from '../../../slice/InputMessageSlice';


//gestisci overflow anche qui per il telefono

function Login() {
    const show = useSelector(selectLogin).show;
    const showButton = useSelector(selectLogin).type;
    const dispatch = useDispatch();
    const [validated, setValidated] = useState(false);
    const [forgot, setForgot] = useState(false);
    const password = useSelector(selectPassword);
    const [email, setEmail] = useState('');
    const [emailForgot, setEmailForgot] = useState('');
    const errValidation = useSelector(selectErrModalVal);
    const navigate = useNavigate();
    const resetInputRef = useRef(null);
    let [resetPassMessage, setResetPassMessage] = useState({ show: false, value: '' });
    const theme = useSelector(selectTheme);
    const [disable, setDisable] = useState(false);
    const logToken = useSelector(selectBearer);
    const user = useSelector(selectUserData).user;

    const handleClose = () => {
        dispatch(setShowLogin({show: false, type: 'logButton'}));
        setValidated(false);
        setForgot(false);
        setEmail('');
        setEmailForgot('');
        dispatch(setErrModalValInit());
        dispatch(setPasswordInit());
        setResetPassMessage({ show: false, value: '' });
        navigate("/");
        setDisable(false);
    };

    function showSign() {
        if(!disable){
            handleClose();
            dispatch(setShowSignUp(true));
        }  
    }

    function handleSubmit(event) {
        event.preventDefault();
        if (resetInputRef.current === document.activeElement) {
            resetPass();
        } else {
            const form = event.currentTarget;
            setValidated(true);
            if (form.checkValidity() === false) {
                event.stopPropagation();
            } else {
                setDisable(true)
                const fetchPromise = fetch(Url + '/varchat/api/auth/login', {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({ username: email, password: password.old }) //username includes also email
                });

                fetchPromise
                    .then((response) => {
                        console.log('login then1', response)
                        if (!response.ok) {
                            /* if (response.status === 409) {
                                //dispatch(setErrModalVal({ show: true, value: 'Invalid credentials!' }));
                            } else {
                                dispatch(setErrModalVal({ show: true, value: 'An error occurred.' }));
                            } */
                            //throw new Error(`HTTP error: ${response.status}`);
                            return Promise.reject(response.json())
                        } else {
                            return response.json();
                        }
                    })
                    .then((data) => {
                        console.log('login then2', data)
                        dispatch(setRefreshToken(data.refresh_token));
                        getUserData(data.access_token);
                    })
                    .catch((error) => {
                        //vedere se aggiungere il messaggio d'errore se dà error 500 ad es. error.name = TypeError (failed to fetch)
                        // dispatch(setErrModalVal({ show: true, value: 'An error occurred.' }));
                        const genericError = "An error occured";
                        console.log('login catch ', error.then(function(r) { console.log(r)}))
                        error.then(function(obj) {
                            if(obj.detail) {
                                dispatch(setErrModalVal({ show: true, value: obj.detail }));
                            } else {
                                dispatch(setErrModalVal({ show: true, value: genericError }));
                            }
                        });
                        setDisable(false);
                    });
            }
        }
    }

    function getUserData(token){ 
        const fetchPromise = fetch(Url + '/varchat/api/auth/user', {
            method: 'GET',
            headers: { 'Content-Type': 'application/json', "Authorization": `Bearer ${token}` }
        });
        fetchPromise
            .then((response) => {
                if (!response.ok) {
                    dispatch(setErrModalVal({ show: true, value: 'An error occurred.' }));
                    throw new Error(`HTTP error: ${response.status}`);
                } else {
                    return response.json();
                }
            })
            .then((data1) => {
                dispatch(setUserData({ firstname: data1.firstName, lastname: data1.lastName, user: data1.username, email: data1.email, aff: data1.institution, country: data1.country }))
                dispatch(setBearer(token));
                dispatch(setLanguage(data1.lang));
                if(showButton === 'settingButton' && data1.lang === 'EN'){
                    dispatch(setShowProfile({show:true, type: 'translationButton'}));
                }
                handleClose();
                dispatch(setPromptFirstLogin(data1.firstLogin));
                checkLoggedUserMAxQueries(token);
            })
            .catch((error) => {
                setDisable(false);
                console.error(`Could not get products: ${error}`);
            });
    }

    function checkLoggedUserMAxQueries(token) {
        const loggedMaxQueriesPromise = fetch(Url + '/varchat/api/user-info/query-limit-check', {
            method: 'GET',
            headers: { 'Content-Type': 'application/json', "Authorization": `Bearer ${token}` }
        });

        loggedMaxQueriesPromise.then((response) => {
            if (!response.ok) {
                if (response.status === 409) {
                    dispatch(setInputMessage('You’ve exceeded the maximum number of allowed queries per day. If you want to know how to increase this limit, please contact varchat@engenome.com'));
                    console.error(`Limit query 409: ${response.status}`);
                }
                throw new Error(`Limit query 409 error: ${response.status}`);
            }
        }).catch((error) => {
        
        });
    }

    function resetPass() {
        if (emailForgot !== '') {
            setDisable(true);
            const fetchPromise = fetch(Url + '/varchat/api/auth/forgot-password', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ username: emailForgot })
            });
            fetchPromise
                .then((response) => {
                    if (!response.ok) {
                        setResetPassMessage({ show: true, value: 'An error occurred.' });
                        throw new Error(`HTTP error: ${response.status}`);
                    } else {
                        return response;
                    }
                })
                .then(() => {
                    handleClose();
                    dispatch(setMailForgotSent(true));
                })
                .catch((error) => {
                    console.error(`Could not get products: ${error}`);
                    setDisable(false);
                });
        }
    }

    if (window.location.pathname === '/firstLogin' && show === false) {
        dispatch(setBearer(''));
        dispatch(setShowLogin({show: true, type: 'logButton'}));
    }

    return (
        <>
            <Modal show={show} onHide={handleClose} backdrop="static" className={'modal ' + theme} centered={isMobile ? 'centered' : false} restoreFocus={false}>
                <Modal.Header>
                    <Modal.Title className='title'>
                        <AccountIcon />
                        Log In</Modal.Title>
                    <Button onClick={handleClose} id='close' className='close-button' disabled={disable}>
                        <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" id='close-icon'><g fill="none" fillRule="evenodd"><path fill="#344D66" fillRule="nonzero" d="M19 6.41 17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z" /></g></svg>
                    </Button>
                </Modal.Header>
                <Modal.Body style={{ pointerEvents : disable ? 'none' : 'auto'}}>
                    <Form noValidate validated={validated} onSubmit={handleSubmit}>
                    {logToken !== '' ? <div className='center' style={{fontSize:'large'}}>You are already logged in as {user}</div> :
                        <Container>
                            <Form.Group>
                                <div name='login-message' style={{ display: showButton === 'logButton' ? 'none' : 'block'}}>{showButton === 'settingButton' ? 'Log In to enable this feature' : showButton === 'automatic' ? 'Join the VarChat community!' : null} </div>
                                <Row className='input-row'>
                                    <label>EMAIL/USERNAME</label>
                                    <Form.Control type="text" placeholder="name@example.com" autoFocus='autofocus' required onChange={(e) => { setEmail(e.target.value); dispatch(setErrModalValInit()) }} />
                                    <Form.Control.Feedback type="invalid" className='invalid'>Please enter your email or your username.</Form.Control.Feedback>
                                </Row>
                            </Form.Group>
                            <Form.Group>
                                <Row className='input-row'>
                                    <label>PASSWORD</label>
                                    <Password />
                                    <Form.Control.Feedback type="invalid" className='invalid'>Please enter your password.</Form.Control.Feedback>
                                </Row>
                            </Form.Group>
                            <div>
                                <Row><Button className="mb-3 primary-button" type='submit' id='signin' disabled={disable}>
                                    LOGIN
                                </Button></Row>
                                <Row>
                                    {errValidation.show ? <p className='mb-2 errorMessage'>{errValidation.value}</p> : null}
                                </Row>
                                <Row className='center'>
                                    <span className="mb-3 link-green lab" onClick={() => setForgot(!forgot)} style={{ width: 'fit-content' }}>Forgot your password?</span>
                                    <div className='lab'>Do not have an account? <span onClick={showSign} className='link-green'>Create an account</span></div>
                                    <span name={forgot ? 'resetPass-exp' : 'resetPass-compact'}>
                                        <label style={{ textAlign: 'left' }}>ENTER YOUR EMAIL BELOW</label>
                                        <input type="email" className='mb-4' placeholder="Email" autoFocus='autofocus' onChange={(e) => { setEmailForgot(e.target.value); dispatch(setErrModalValInit()) }} ref={resetInputRef} />
                                        <Button className="primary-button" type='button' id='reset' onClick={resetPass} disabled={disable}>
                                            SEND RESET PASSWORD LINK
                                        </Button>
                                        {resetPassMessage.show === true ? <p className='mb-0 mt-2 errorMessage'>{resetPassMessage.value}</p> : null}
                                    </span>
                                </Row>
                            </div>
                        </Container>}
                    </Form>
                </Modal.Body>
            </Modal>
        </>
    );
}

export default Login;