import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useState, useEffect } from 'react';
import { selectBearer } from '../../slice/bearerSlice';
import QuestionRow from './QuestionRow';
import ReadMore from './ReadMore';
import { Url } from '../../constants/global.js';
import Rating from './Rating/Rating';


const TopicContainer = function Message(props) {
    console.log('TopicContainer', props)
    const chat = props.chat;
    const ind = props.ind;
    const conversationUuid = props.conversationUuid;
    const dispatch = useDispatch();
    const logToken = useSelector(selectBearer);
    const [expand, setExpand] = useState(false);
    const [registeredClick, setRegisteredClick] = useState(false);

    const handleClick = (e) => {
        /* dispatch(setShowProfile({show: false, type: 'profileButton'}));
        dispatch(setInfo(false));
        dispatch(setShowHelpCenter(false));
        dispatch(setInputMessage(data)); */
        if(!registeredClick) {
            const fetchPromise = fetch(Url + '/varchat/api/user-info/topic/' + chat.topic_uuid + '/select', {
                method: 'PUT',
                headers: { 'Content-Type': 'application/json', "Authorization": `Bearer ${logToken}` }
            });
            fetchPromise
                .then((response) => {
                    if (!response.ok) {
                        throw new Error(`HTTP error: ${response.status}`);
                    } else {
                        setRegisteredClick(true);
                    }
                }).catch((error) => {
                    console.log(error)
                });
        }
    };

    return (
        <div className='message-section' onClick={handleClick}>
            <div name='ref-title' onClick={() => setExpand(!expand)}>
                <span>
                    <p>{chat.topic}</p>
                </span>
                <ReadMore expand={expand} setExpand={setExpand} />
            </div>
            <div className={expand ? "section-exp" : 'section-compact'}>
                <br></br>
                {chat.questions.map((q, i) => {
                    return <QuestionRow key={i} question={q} conversationUuid={conversationUuid} dateTime={props.dateTime} />
                })
                }
                <Rating ind={props.ind} scope={'answer'} />

            </div>
        </div>
    )
}

export default TopicContainer;