import React from 'react';
import { selectBearer, selectController, setController } from '../../slice/bearerSlice';
import { deleteMessage, selectMessages, setMessage, updateMessage, updateMessageText } from '../../slice/MessageSlice';
import { useDispatch, useSelector } from 'react-redux';
import { Url } from '../../constants/global.js';
import { useCacheBuster } from "react-cache-buster";
import { fetchEventSource } from '@microsoft/fetch-event-source';
import { selectStreaming, setShowLoader, setStreaming, selectTranslating, selectShowLoader } from '../../slice/loaderSlice';
import { selectInputMessage, selectLineBreak, selectSend, selectUrlText, setInputMessage, setLineBreak, setSend, setUrlText } from '../../slice/InputMessageSlice';


const QuestionRow = function Message(props) {
    const messages = useSelector(selectMessages);
    const { checkCacheStatus } = useCacheBuster();
    const controller = useSelector(selectController);
    const signal = controller.signal;
    const logToken = useSelector(selectBearer);
    const question = props.question;
    const conversationUuid = props.conversationUuid;
    const dispatch = useDispatch();
    const topicCreationDate = props.dateTime;

    const handleClick = (e) => {
        if(!isQuestionExpired()) {
            dispatch(setMessage({
                text: question.question,
                user: 'You',
                userInput: question.question,
                statusCode: 0,
                language: 'EN',  //
                statusInfoCode: 0
            }));
            sendQuestion();
        } else {
            alert('TODO question older than 1d')
        }
    };

    const isQuestionExpired = () => {
        return (Date.now() - new Date(topicCreationDate)) > 86400000;
    };

    async function sendQuestion() {
        if(logToken) {
            checkCacheStatus();
            dispatch(setStreaming(true));
            dispatch(setLineBreak(false));
            let loading_message = 'Waiting for answer';
            try {
                await streamSummary();
            } catch (error) {
                console.error(`Failed to streamSummary: ${error}`);
            }
        } else {
            // non loggato
        }
    }

    async function streamSummary(param, text) {
        let jsonData = {};
        let headers;
        logToken
            ? headers = {'Content-Type': 'application/json', 'Authorization': `Bearer ${logToken}`}
            : headers = {'Content-Type': 'application/json'};
        await fetchEventSource(Url + '/varchat/api/request/answer?' + new URLSearchParams({ conversation_uuid: conversationUuid, question: question.question, question_uuid: question.question_uuid, stream: true }), {
            method: 'GET',
            headers: headers,
            openWhenHidden: true,
            signal: signal,
            onopen(response) {
                if (!response.ok) {
                    throw new Error(`HTTP error: ${response.status}`);
                } else {
                    return;
                }
            },
            onmessage(msg) {
                try {
                    const data = JSON.parse(msg.data);
            //        console.log('onmessage data', data);
                    if (Object.keys(jsonData).length === 0) {
                        console.log('initial data', JSON.parse(msg.data))
                    //    dispatch(deleteMessage());
                        dispatch(setShowLoader(false));
                        dispatch(setMessage({
                            text: "",
                            user: 'VarChat',
                            userInput: text,
                            statusCode: data.status_answer_code,
                            language: 'EN',
                            statusInfoCode: data.status_info_code,
                            type: 'answer'
                        }));
                        jsonData = {
                            evaluate: true
                        };
                    } else {
                        if (data.status_answer_code !== undefined) {
                            jsonData = Object.assign(jsonData, { statusCode: data.status_answer_code });
                            // console.log('dati', jsonData, 'dataa', data);
                        } else {
                            dispatch(updateMessageText({ i: messages.length + 1, text: data }));
                            // console.log('status answer undefined', data)
                        }
                    }
                } catch {
                    const word = msg.data === '' ? '\n' : msg.data;
                    // console.log('data word', msg.data, 'word', word)
                    dispatch(updateMessageText({ i: messages.length + 1, text: word }));
                }
            },
            onclose() {
                console.log('MESSAGE onClose --> dispatch(updateMessage)', messages.length + 1, jsonData);
                dispatch(updateMessage({ i: messages.length + 1, obj: jsonData }));
                dispatch(setStreaming(false));
                //                dispatch(setStreaming(false)); moved to use effect of messages (for scrolling purposes)
            },
            onerror(error) {
                console.log('ON ERROR', error)
                dispatch(setStreaming(false));
                dispatch(deleteMessage());
                dispatch(setMessage({ user: 'VarChat', statusCode: 5 }));
                throw error;
            }
        });
    }

/*     async function fetchAnswer() {
        const fetchPromise = fetch(Url + '/varchat/api/request/answer?' + new URLSearchParams({ conversation_uuid: conversationUuid, question: question.question, question_uuid: question.question_uuid }), {
            method: 'GET',
            headers: { 'Content-Type': 'application/json', "Authorization": `Bearer ${logToken}` }
        });
        fetchPromise
            .then((response) => {
                if (!response.ok) {
                    throw new Error(`HTTP error: ${response.status}`);
                } else {
                    return response.json();
                }
            }).then((data) => {
                
            }).catch((error) => {
                console.log(error)
            });
    } */

    return (
        <div className="example" onClick={handleClick} style={{marginRight: "16px", width: "100%", display: "flex", justifyContent: "space-between"}}>
            <span>{question.question}</span>
            <img src="/static/media/arrow.c526c2948ba05c95e5d68715dfd30546.svg" alt="arrow" data-cmp-ab="2" data-cmp-info="10"></img>
        </div>
    )
}

export default QuestionRow;