import { createSlice } from '@reduxjs/toolkit';

const initialState = {fetchHistory: true};

export const profileHistorySlice = createSlice({
    name: 'history',
    initialState,
    reducers: {
        setRefreshProfileHistory: (state, action) => {
        //    console.log('--> setRefreshProfileHistory', state, action)
            state.fetchHistory = action.payload;
        }
    }
});

export const { setRefreshProfileHistory } = profileHistorySlice.actions;

// The function below is called a selector and allows us to select a value from
// the state.
export const selectRefreshHistory = (state) =>  state.history.fetchHistory;

export default profileHistorySlice.reducer;