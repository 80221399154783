import React from 'react';
import robot from '../../Images/varchat-icon-robot-2.svg';
import robotDark from '../../Images/varchat-icon-robot-dark.svg';
import user from '../../Images/varchat-icon-user-2.svg';
import userDark from '../../Images/varchat-icon-user-dark.svg';
import { forwardRef, useState, useEffect } from 'react';
import Rating from './Rating/Rating';
import { useDispatch, useSelector } from 'react-redux';
import { selectMessages, updateMessage } from '../../slice/MessageSlice';
import WelcomeMessage from './WelcomeMessage';
import examples from '../../constants/example';
import { isMobile } from 'react-device-detect';
import CopyIcon from './CopyIcon';
import { selectLanguage, selectTheme } from '../../slice/SettingSlice';
import MessageFlagIcons from './MessageFlagIcons';
import { setShowHelpCenter, setShowLogin, setShowProfile } from '../../slice/ModalVisibilitySlice';
import Loader from './Loader/Loader';
import { selectBearer } from '../../slice/bearerSlice';
import { selectStreaming, selectTranslating } from '../../slice/loaderSlice';
import ReadMore from './ReadMore';
import WarningIcon from '../../Images/WarningIcon';
import ErrorIcon from '../../Images/ErrorIcon';
import ExampleList from './ExampleList';
import TopicContainer from './TopicContainer';
import { Url } from '../../constants/global.js';


const MessageTopic = forwardRef(function Message(props, ref) {
    const dispatch = useDispatch();
    const messages = useSelector(selectMessages);
    let message = messages[props.ind];
    message.mex ? message = message.mex : message = message;
console.log("\nMessageTopic" ,props, ref, messages, message)
    const theme = useSelector(selectTheme);
    const robotIcon = theme === 'light' ? robot : robotDark;
    const userIcon = theme === 'light' ? user : userDark;
    const language = useSelector(selectLanguage);
    const logToken = useSelector(selectBearer);
    const translating = useSelector(selectTranslating);
    const streaming = useSelector(selectStreaming);
    const [isFetching, setIsFetching] = useState(true);
    let messageBody = '';
    let messageToCopy = '';
    const statusInfoMessage = 'We cannot retrieve all the information at this moment, so the answer may be partial or incomplete. If you\'re not satisfied with this summary, please try again later.';
    const errMessage = <span>VarChat is unable to complete this request right now because of an internal error. Please, try again.<br />If the problem persists, please contact <a className='link-error' href='mailto:varchat@engenome.com'>varchat@engenome.com</a></span>;
    const helpCenterLink = <div className='mt-2'>For more details on the supported input formats, please visit our <span className='message-link' onClick={() => { dispatch(setShowHelpCenter(true)) }}>Help Center</span></div>
    const statusCodeLegend = [
        { statusCode: -1, type: 'errorValidation', text: '' }, //-1 case fe validation fail, i messaggi sono gestiti in SendMessageButton in base all'errore di validazione
        { statusCode: 0, type: (message.statusInfoCode ? 'warning' : 'ok'), text: '' }, //type warning serve per inserire l'icona gialla quando è 0 ma Status_info_code è true
        { statusCode: 1, type: 'warning', text: 'VarChat identified supporting literature, but it was not used to generate the summary.' },
        { statusCode: 2, type: 'warning', text: 'VarChat did not find any supporting literature.' },
        { statusCode: 3, type: 'error', text: 'We\'re currently encountering issues and can\'t provide a summary right now. Please try again later.' },
        { statusCode: 4, type: 'error', text: (message.statusInfoCode ? errMessage : 'VarChat is unable to retrieve information on this variant. Please check the information you provided, or try another VarChat-supported format (HGVS or genomic coordinates notation).') },
        { statusCode: 5, type: 'error', text: errMessage },
        { statusCode: 6, type: 'error', text: (message.statusInfoCode ? errMessage : 'These genomic coordinates seem to be incorrect. Please check the information you provided and verify the genomic assembly. Alternatively, try another VarChat-supported format (HGVS or rs notation).') },
        { statusCode: 7, type: 'error', text: (message.statusInfoCode ? errMessage : 'Please check the gene symbol since it’s not valid.') },
        { statusCode: 8, type: 'error', text: (message.statusInfoCode ? errMessage : 'Please check the transcript ID you provided or try to query by gene.') },
        { statusCode: 9, type: 'error', text: errMessage },
        { statusCode: 10, type: 'warning', text: 'VarChat is unable to find the answer to this question in the identified literature.' }
    ];

    //metto il messaggio dell'errore di traduzione perché non si può salvare in uno stato un html
    const translationError = <span className='error'><ErrorIcon /><span>I was unable to complete the translation right now. Please try again later. <br />If the problem persists, please contact <a className='link-error' href='mailto:varchat@engenome.com'>varchat@engenome.com</a></span></span>;
    const translation = (message.translation && message.translation[message.language] !== undefined ? (message.translation[message.language] === 'error' ? (translating ? <Loader loading_message={'TRANSLATING...'} /> : translationError) : message.translation[message.language]) : <Loader loading_message={'TRANSLATING...'} />); //in message.translation mi salvo le n traduzioni nelle varie lingue

    let messageText = (message.language && message.language !== 'EN' ? translation : message.text);
    const [responseJSON, setResponseJSON] = useState(null);

    useEffect(() => { //to set time only once
        if (!message.time) {
            console.log('TOPIC dispatch update message from useEffect')
            dispatch(updateMessage({ i: props.ind, obj: { time: getFormattedTime() } }))
        }
        //fetchQuestions();
    }, [])

    useEffect(() => {
        fetchQuestions();
    }, [])

    /* useEffect(() => {
        console.log('TOPIC useEffect --> updateMessage', responseJSON, message.text)
        dispatch(updateMessage({ i: props.ind, obj: message }));
    }, [responseJSON]) */

    function getFormattedTime() {
        const date = new Date();
        const timeComponents = [date.getHours(), date.getMinutes()];
        return timeComponents
            .map(component => {
                const pad = (component < 10) ? '0' : '';
                return pad + component;
            })
            .join(':');
    }

    function getStars(count) {
        let stars = [];
        for (let i = 0; i < count; i++) {
            stars.push(<span key={'star ' + i} className='rating-icon full clinvar-stars-check' />)
        }
        return stars;
    }

    function clickNeedTranslation() {
        if(logToken !== ''){
            dispatch(setShowProfile({show:true, type: 'translationButton'}));
        } else {
            dispatch(setShowLogin({ show: true, type: 'settingButton' }));
        }
    }

    //function to extract the text of an html react element (if the passed object is already a string returns it)
    function textContent(elem) {
        if (!elem) {
            return '';
        }
        if (typeof elem === 'string') {
            return elem;
        }
        const children = elem.props && elem.props.children;
        if (children instanceof Array) {
            return children.map(textContent).join('');
        }
        return textContent(children);
    }

    if (message.statusCode !== undefined) {
        let messageStatus = message.statusCode;
        if(/^\-1(\.\d)?$/.test(message.statusCode)){
            messageStatus = -1;
        }
        let statusCodeText = statusCodeLegend[messageStatus + 1].text;
        if(message.statusInfoCode && statusCodeLegend[messageStatus + 1].type !== 'error'){
            statusCodeText = statusInfoMessage;
        }

        messageBody = <><p className={statusCodeLegend[messageStatus + 1].type}>
            {statusCodeLegend[messageStatus + 1].type === 'warning' ? <WarningIcon /> : statusCodeLegend[messageStatus + 1].type === 'error' ? <ErrorIcon /> : null}
            {statusCodeText}
        </p>
            {!message.statusInfoCode && examples['status_' + messageStatus] !== undefined ? <><ExampleList type={'status_' + messageStatus} />{helpCenterLink}</> : null}
            {messageText}
            {messageStatus === -1 ? <>{message.statusCode !== -1 ? <div className='mt-2'><ExampleList type={'status_' + message.statusCode.toString().split('.')[1]} /></div> : null}{helpCenterLink}</> : null}
            {message.stopText !== undefined ? <div style={{ color: 'var(--soft-red)' }}>{message.stopText}</div> : ''}
        </>
        let title = ''
        try{
            title = '\n' + examples['status_' + messageStatus].title;
        } catch{
        }
        messageToCopy = textContent(statusCodeText) + (!message.statusInfoCode && examples['status_' + messageStatus] !== undefined ? '\n' + examples['status_' + messageStatus].text + title + '\n' + examples['status_' + messageStatus].exArray.map((str) => { return str.ex }).join('\n') + '\nNote: ' + examples['status_' + messageStatus].note + '\n' + textContent(helpCenterLink) + '\n' : '') + (messageText !== undefined ? messageText : '');
        if(messageStatus === -1 && message.statusCode !== -1){
            try{
                title = '\n' + examples['status_' +message.statusCode.toString().split('.')[1]].title;
            }catch{}
            messageToCopy = messageToCopy.concat('\n'  + examples['status_' + message.statusCode.toString().split('.')[1]].text + title + '\n' + examples['status_' + message.statusCode.toString().split('.')[1]].exArray.map((str) => { return str.ex }).join('\n') + '\nNote: ' + examples['status_' + message.statusCode.toString().split('.')[1]].note + '\n' + textContent(helpCenterLink));
        }
    } else {
        messageBody = <>{messageText}{message.stopText !== undefined ? <div style={{ color: 'var(--soft-red)' }}>{message.stopText}</div> : ''}</>;
        messageToCopy = textContent(messageText);
        if (props.ind === 0) {
            messageToCopy = messageToCopy.concat('\n' + examples['welcome'].exArray.map((str) => { return str.ex }).join('\n'));
        }
    }


    async function fetchQuestions() {
        const fetchPromise = fetch(Url + '/varchat/api/request/questions?' + new URLSearchParams({ conversation_uuid: props.mex.conversationUuid }), {
            method: 'GET',
            headers: { 'Content-Type': 'application/json', "Authorization": `Bearer ${logToken}` }
        });
        fetchPromise
            .then((response) => {
                if (!response.ok) {
                    throw new Error(`HTTP error: ${response.status}`);
                } else {
                    return response.json();
                }
            }).then((data) => {
                console.log('topic response json', data);
            //    message.text = JSON.stringify(data);
console.log('TOPIC dispatch(updateMessage)', props.ind, message )
                
console.log('TOPIC setResponseJSON')
                setIsFetching(false);
                setResponseJSON(JSON.stringify(data));
                dispatch(updateMessage({ i: props.ind, obj: Object.assign({}, message, {text: JSON.stringify(data)} ) }));
                
            }).catch((error) => {
                setIsFetching(false);
                console.log(error)
            });
    }

    return (

        <span className={streaming ? isFetching ? 'message hidden' : 'message' : 'message'} ref={ref} key={'message ' + props.ind}>
            {isMobile 
                ? null
                : message.user === 'VarChat'
                    ?   <img src={robotIcon} alt='varChat' className='icon'></img>
                    :   <img src={userIcon} alt='user' className='icon'></img>}
            <span name='message-text' className={message.user.toLowerCase()}>
                <span name='message-header'>
                    <span>
                        {!isMobile
                            ? null
                            : message.user === 'VarChat'
                                ?   <img src={robotIcon} alt='varChat' className='icon' style={{ marginRight: '8px' }}></img>
                                :   <img src={userIcon} alt='user' className='icon' style={{ marginRight: '8px' }}></img>}
                        <span style={{ display: isMobile ? 'block' : 'flex' }}>
                            <p name='name'>{message.user}</p>
                            <p name='time'>{message.time}</p>
                        </span>
                    </span>
                    <span style={{ alignItems: 'center', marginRight: '-8px' }}>
                        {/* {streaming && props.ind === messages.length - 1
                            ? null
                            :
                                <>
                                    <CopyIcon ind={props.ind} messageToCopy={messageToCopy} type='copyText' />
                                    {message.evaluate ? <>
                                        <CopyIcon ind={props.ind} type='copyLink' />
                                        {statusCodeLegend[message.statusCode + 1].type !== 'error'
                                            ? language !== 'EN' && logToken !== ''
                                                ?   <MessageFlagIcons ind={props.ind} />
                                                :   <button className='secondary-button small' name='translation-btn' onClick={clickNeedTranslation}>{!isMobile ? 'NEED A TRANSLATION?' : <svg name='translate-svg' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" id="language"><path d="M4 25a1 1 0 0 1-1-1V5a2.002 2.002 0 0 1 2-2h18a2.002 2.002 0 0 1 2 2v14a2.002 2.002 0 0 1-2 2H8.414l-3.707 3.707A.999.999 0 0 1 4 25zM23 4.998 5 5v16.586l2.293-2.293A1 1 0 0 1 8 19h15zM44 45a.999.999 0 0 1-.707-.293L39.586 41H25a2.002 2.002 0 0 1-2-2V25a2.002 2.002 0 0 1 2-2h18a2.002 2.002 0 0 1 2 2v19a1 1 0 0 1-1 1zM25 25v14h15a1 1 0 0 1 .707.293L43 41.586V25z"></path><path d="M10.998 17a1 1 0 0 1-.14-1.99 6.125 6.125 0 0 0 3.435-1.717A6.057 6.057 0 0 0 15.73 11H11a1 1 0 0 1 0-2h6a1 1 0 0 1 .99 1.141 8.07 8.07 0 0 1-6.848 6.849 1.008 1.008 0 0 1-.143.01Z"></path><path d="M14 11a1 1 0 0 1-1-1V8a1 1 0 0 1 2 0v2a1 1 0 0 1-1 1zm4.001 6q-.039 0-.08-.003a7.522 7.522 0 0 1-5.764-3.459 1 1 0 0 1 1.686-1.076 5.516 5.516 0 0 0 4.235 2.54A1 1 0 0 1 18.001 17zm11.998 20a1 1 0 0 1-.893-1.447l4-8a1 1 0 0 1 1.788.894l-4 8A1 1 0 0 1 30 37z"></path><path d="M38.001 37a1 1 0 0 1-.895-.553l-4-8a1 1 0 0 1 1.788-.894l4 8A1 1 0 0 1 38.002 37Z"></path><path d="M37.483 33.967h-6.966a1 1 0 0 1 0-2h6.966a1 1 0 0 1 0 2zM10 27a1 1 0 0 1-.707-1.707l2-2a1 1 0 0 1 1.414 1.414l-2 2A.997.997 0 0 1 10 27z"></path><path d="M14 27a.997.997 0 0 1-.707-.293l-2-2a1 1 0 0 1 1.414-1.414l2 2A1 1 0 0 1 14 27Z"></path><path d="M20 35h-7a2.002 2.002 0 0 1-2-2v-9a1 1 0 0 1 2 0v9h7a1 1 0 0 1 0 2zm16-14a1 1 0 0 1-.707-1.707l2-2a1 1 0 0 1 1.414 1.414l-2 2A.997.997 0 0 1 36 21z"></path><path d="M36 21a.997.997 0 0 1-.707-.293l-2-2a1 1 0 0 1 1.414-1.414l2 2A1 1 0 0 1 36 21Z"></path><path d="M36 21a1 1 0 0 1-1-1v-9h-7a1 1 0 0 1 0-2h7a2.002 2.002 0 0 1 2 2v9a1 1 0 0 1-1 1Z"></path></svg>}</button> : null}
                                    </>
                                        : null
                                    }
                                </>
                        } */}
                    </span>
                </span>
                { JSON.parse(message.text).chat.map((chat, j) => {
                    return <TopicContainer key={j} chat={chat} ind={props.ind} conversationUuid={props.mex.conversationUuid} dateTime={JSON.parse(message.text).date_time}/>
                }) }
                <br></br>
                <Rating ind={props.ind} scope={'topic'} />                 

            </span>
            
            
            {message.evaluate && statusCodeLegend[message.statusCode + 1].type !== 'error' ? <Rating ind={props.ind} scope={'topic'} /> : null}

        </span>


        
    );
})

export default MessageTopic;