function ReadMore(props){
    const {expand, setExpand} = props;

    return(
        <span className='readMore' onClick={() => setExpand(!expand)}>
            <p>{expand ? 'READ LESS' : 'READ MORE'}</p>
            <svg className={expand ? 'rotate-arrow' : 'no-rotate-arrow'} xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#344d66"><path d="M24 24H0V0h24v24z" fill="none" opacity=".87" /><path d="M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6-1.41-1.41z" /></svg>
        </span>

    );
}

export default ReadMore;